import { type FC, useMemo } from 'react';
import { Heading, Button } from '@mediahuis/chameleon-react';
import { mySectionCtaTextSize } from '@hubcms/brand';

import { createTrackingClickHandler } from '@hubcms/data-access-tracking';
import { createClickNavigationEvent, mapClickData } from '@hubcms/utils-tracking';
import type { CallToActionProps } from '@hubcms/domain-my-section';

import styles from './call-to-action.module.scss';

const CallToAction: FC<CallToActionProps> = ({ text, buttonText, buttonLink, trackingData }) => {
  const handleOnClick = useMemo(() => {
    const clickNavigationData = mapClickData(trackingData);

    if (!clickNavigationData) {
      return undefined;
    }

    return createTrackingClickHandler(createClickNavigationEvent(clickNavigationData));
  }, [trackingData]);

  return (
    <div className={styles.callToAction}>
      {text && (
        <Heading size={mySectionCtaTextSize} level={3} className={styles.text}>
          {text}
        </Heading>
      )}
      <Button as="a" href={buttonLink} onClick={handleOnClick} appearance="primary" width="full">
        {buttonText}
      </Button>
    </div>
  );
};

export default CallToAction;
